import React from "react";
import { graphql } from "gatsby";
import { Container, Row, Col, Tab, Nav, Accordion } from "react-bootstrap";
import Layout from "../../components/Layout";
import Slider from "react-slick";
import Img from "gatsby-image";
import ContextAwareToggle from "../../components/ContextAwareToggle";
import SEO from "../../components/SEO";

class Software extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.discovery-analyze').classList.add('active');
    }

    render() {    
        const { data } = this.props;
        const introduction = data.allMarkdownRemark.nodes;
        const web_portal = data.web_portal.nodes;
        const msteam_platform = data.msteam_platform.nodes;
        const azure_logic = data.azure_logic.nodes;
        const mobile = data.mobile.nodes;
        const cycle = data.cycle.nodes;
        const world_trusted_technologies = data.world_trusted_technologies.nodes;
        const sample_projects = data.sample_projects.nodes;

        const settings = {
            dots: false,
            speed: 500,
            autoplay: false,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        return (
            <Layout>
                <SEO title="Software Development Service" description="Appvity provides full-cycle consulting from business analysis, requirements, development, and support. Our development service focuses on Office 365, Azure, SharePoint, and Power platform." meta={[{ name: `keywords`, content: ["SharePoint based app", "Appvity software developement service", "app on Microsoft technologies", "build tools on Microsoft Teams", "task management"] }]} pathname="/services/software-development" />
                <div className="main-content-padding">
                    <div className="app-general-seciton app-software-development-introduction" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <div className="app-software-development-introduction-detail">
                                <Row className="app-software-development-introduction-row-custom">
                                    <Col className="app-software-development-introduction-col-custom" xs={12} md={12} lg={5}>
                                        {introduction.map((res) => (
                                            <div className="app-software-development-introduction-left" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                    </Col>
                                    <Col xs={12} md={12} lg={1}></Col>
                                    <Col className="app-software-development-introduction-col-custom" xs={12} md={12} lg={6}>
                                        <div className="app-software-development-introduction-right">
                                            <img className="app-software-development-introduction-img" src="/app-software-development-introduction.png" alt="software development introduction" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-software-core-competencies" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <div className="app-title">
                                <h2>Software Core Competencies</h2>
                            </div>
                            <div className="app-software-core-competencies-detail">
                                <Row>
                                    <Col xs={12} md={12} lg={6}>
                                        <Row>
                                            <Col xs={12} md={12} lg={6}>
                                                {web_portal.map((res) => (
                                                    <div className="app-software-core-competencies-detail-content app-web-portal" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                                ))}
                                            </Col>
                                            <Col xs={12} md={12} lg={6}>
                                                {msteam_platform.map((res) => (
                                                    <div className="app-software-core-competencies-detail-content app-msteam-platform" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                                ))}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} md={12} lg={6}>
                                        <Row>
                                            <Col xs={12} md={12} lg={6}>
                                                {azure_logic.map((res) => (
                                                    <div className="app-software-core-competencies-detail-content app-azure-logic" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                                ))}
                                            </Col>
                                            <Col xs={12} md={12} lg={6}>
                                                {mobile.map((res) => (
                                                    <div className="app-software-core-competencies-detail-content app-mobile" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                                ))}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-development-cycle" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <div className="app-title">
                                <h2>Development Cycle</h2>
                            </div>
                            <div className="app-development-cycle-detail">
                                <div className="app-development-cycle-pc">
                                    <Row>
                                        <Col xs={12} md={12} lg={8}>
                                            {cycle.map((res) => (
                                                <div 
                                                    className={res.frontmatter.slug + " " + "app-development-cycle-pc-item"} 
                                                    key={res.id}
                                                    onMouseOver={() => {
                                                        if (res.frontmatter.slug === "discovery-analyze") {
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.discovery-analyze').classList.add('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.design').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.development').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.quality-assurance').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.migration').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.training-support').classList.remove('active');
                                                        } else if (res.frontmatter.slug === "design") {
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.discovery-analyze').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.design').classList.add('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.development').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.quality-assurance').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.migration').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.training-support').classList.remove('active');
                                                        } else if (res.frontmatter.slug === "development") {
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.discovery-analyze').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.design').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.development').classList.add('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.quality-assurance').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.migration').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.training-support').classList.remove('active');
                                                        } else if (res.frontmatter.slug === "quality-assurance") {
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.discovery-analyze').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.design').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.development').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.quality-assurance').classList.add('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.migration').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.training-support').classList.remove('active');
                                                        } else if (res.frontmatter.slug === "migration") {
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.discovery-analyze').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.design').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.development').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.quality-assurance').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.migration').classList.add('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.training-support').classList.remove('active');
                                                        } else if (res.frontmatter.slug === "training-support") {
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.discovery-analyze').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.design').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.development').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.quality-assurance').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.migration').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.training-support').classList.add('active');
                                                        }
                                                    }}
                                                    onFocus={() => {
                                                        if (res.frontmatter.slug === "discovery-analyze") {
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.discovery-analyze').classList.add('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.design').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.development').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.quality-assurance').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.migration').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.training-support').classList.remove('active');
                                                        } else if (res.frontmatter.slug === "design") {
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.discovery-analyze').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.design').classList.add('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.development').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.quality-assurance').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.migration').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.training-support').classList.remove('active');
                                                        } else if (res.frontmatter.slug === "development") {
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.discovery-analyze').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.design').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.development').classList.add('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.quality-assurance').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.migration').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.training-support').classList.remove('active');
                                                        } else if (res.frontmatter.slug === "quality-assurance") {
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.discovery-analyze').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.design').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.development').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.quality-assurance').classList.add('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.migration').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.training-support').classList.remove('active');
                                                        } else if (res.frontmatter.slug === "migration") {
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.discovery-analyze').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.design').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.development').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.quality-assurance').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.migration').classList.add('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.training-support').classList.remove('active');
                                                        } else if (res.frontmatter.slug === "training-support") {
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.discovery-analyze').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.design').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.development').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.quality-assurance').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.migration').classList.remove('active');
                                                            document.querySelector('.app-development-cycle .app-development-cycle-detail .app-development-cycle-pc img.training-support').classList.add('active');
                                                        }
                                                    }}
                                                >
                                                    <h4>{res.frontmatter.title}</h4>
                                                    <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                </div>
                                            ))}
                                        </Col>
                                        <Col xs={12} md={12} lg={4}>
                                            {cycle.map((res) => (
                                                <img className={res.frontmatter.slug} src={res.frontmatter.thumb.publicURL} alt="app software development service cycle img" key={res.id} />
                                            ))}
                                        </Col>
                                    </Row>
                                </div>
                                <div className="app-development-cycle-mobile-tablet">
                                    <Slider {...settings}>
                                        {cycle.map((res) => (
                                            <div className="app-development-cycle-mobile-tablet-item" key={res.id}>
                                                <h4>{res.frontmatter.title}</h4>
                                                <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                <img src={res.frontmatter.thumb.publicURL} alt="app software development service cycle img" />
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-world-trusted-technologies" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <div className="app-title">
                                <h2>Implemented using Trusted Technologies Worldwide</h2>
                            </div>
                            <div className="app-world-trusted-technologies-detail">
                                <Row>
                                    {world_trusted_technologies.map((res) => (
                                        <Col xs={12} md={12} lg={4} key={res.id}>
                                            <div className="app-world-trusted-technologies-detail-content" dangerouslySetInnerHTML={{ __html: res.html }} />
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </Container>
                    </div>
                    {/* <div className="app-general-seciton app-sample-software-development-projects" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <div className="app-title">
                                <h2>Sample Software Development Projects</h2>
                            </div>
                            <div className="app-sample-software-development-projects-detail">
                                <div className="app-sample-software-development-projects-pc">
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="collaboration-portal">
                                        <Row>
                                            <Col xs={12} md={12} lg={3}>
                                                <Nav variant="pills" className="flex-column">
                                                    {sample_projects.map((res) => (
                                                        <Nav.Item key={res.id}>
                                                            <Nav.Link eventKey={res.frontmatter.slug}>{res.frontmatter.title}</Nav.Link>
                                                        </Nav.Item>
                                                    ))}
                                                </Nav>
                                            </Col>
                                            <Col xs={12} md={12} lg={1}></Col>
                                            <Col xs={12} md={12} lg={8}>
                                                <Tab.Content>
                                                    {sample_projects.map((res) => (
                                                        <Tab.Pane eventKey={res.frontmatter.slug} key={res.id}>
                                                            <Row>
                                                                <Col xs={12} md={12} lg={7}>
                                                                    <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                                </Col>
                                                                <Col xs={12} md={12} lg={5}>
                                                                    <Img
                                                                        fluid={
                                                                            res.frontmatter
                                                                                .thumb
                                                                                .childImageSharp
                                                                                .fluid
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Tab.Pane>
                                                    ))}
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </div>
                                <div className="app-sample-software-development-projects-mobile-tablet">
                                    <Accordion defaultActiveKey="collaboration-portal">
                                        {sample_projects.map((res) => (
                                            <div className="app-sample-software-development-projects-mobile-tablet-content" key={res.id}>
                                                <div className="app-sample-software-development-projects-mobile-tablet-header">
                                                    <ContextAwareToggle
                                                        eventKey={res.frontmatter.slug}
                                                    >
                                                        <h6>{res.frontmatter.title}</h6>
                                                    </ContextAwareToggle>
                                                </div>
                                                <Accordion.Collapse eventKey={res.frontmatter.slug}>
                                                    <div className="app-sample-software-development-projects-mobile-tablet-body">
                                                        <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                        <Img
                                                            fluid={
                                                                res.frontmatter
                                                                    .thumb
                                                                    .childImageSharp
                                                                    .fluid
                                                            }
                                                        />
                                                    </div>
                                                </Accordion.Collapse>
                                            </div>
                                        ))}
                                    </Accordion>
                                </div>
                            </div>
                        </Container>
                    </div> */}
                </div>
            </Layout>
        )
    }
}

export default Software;

export const query = graphql`
    query SoftwareDevelopmentService {
        allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "software_development_service_introduction" } } }
        ) {
            nodes {
                html
                id
            }
        }
        web_portal: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "software_development_service_web_portal" } } }
        ) {
            nodes {
                html
                id
            }
        }
        msteam_platform: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "software_development_service_msteam_platform" } } }
        ) {
            nodes {
                html
                id
            }
        }
        azure_logic: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "software_development_service_azure_logic" } } }
        ) {
            nodes {
                html
                id
            }
        }
        mobile: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "software_development_service_mobile" } } }
        ) {
            nodes {
                html
                id
            }
        }
        cycle: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "software_development_service_cycle" } } }
            sort: { fields: frontmatter___createAt, order: ASC }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    slug
                    thumb {
                        publicURL
                    }
                }
                id
            }
        }
        world_trusted_technologies: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "software_development_service_world_trusted_technologies" } } }
            sort: { fields: frontmatter___createAt, order: ASC }
        ) {
            nodes {
                html
                id
            }
        }
        sample_projects: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "software_development_service_sample_projects" } } }
            sort: { fields: frontmatter___createAt, order: ASC }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    slug
                    thumb {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                id
            }
        }
    }
`;